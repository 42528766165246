/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>

      <div className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>About Us</h4>
                <ul>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="#about">About Us</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="#service">Our Services</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="#portfolio">Portfolio</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="#team">Team</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="careers.html">Career</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-forward"></i>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-contact" style={{ fontSize: '1.5rem' }}>
                <h4>Contact Us</h4>
                <p>
                  <br />
                  <strong>Phone:</strong> +91 9585957899
                  <br />
                  <strong>Email:</strong> director@essdeegreen.com
                  <br />
                </p>

                <div className="social-links">
                  <a href="https://www.facebook.com/" role="link" aria-label="Facebook">
                    <i className="ion-logo-facebook"></i>
                  </a>
                  <a href="https://twitter.com/login?lang=en" role="link" aria-label="Twitter">
                    <i className="ion-logo-twitter"></i>
                  </a>
                  <a href="https://www.linkedin.com/" role="link" aria-label="LinkedIn">
                    <i className="ion-logo-linkedin"></i>
                  </a>
                  <a href="https://www.instagram.com/" role="link" aria-label="Instagram">
                    <i className="ion-logo-instagram"></i>
                  </a>
                  <a href="https://accounts.google.com/servicelogin/signinchooser?flowName=GlifWebSignIn&flowEntry=ServiceLogin" role="link" aria-label="Google+">
                    <i className="ion-logo-googleplus"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 copyright" style={{ color: '#fff', fontSize: '1.3rem' }}>
              Copyright &copy; EssDee Green Technologies Website. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>

      {/* Use a button or make the back-to-top link actionable */}
      <a href="#top" className="back-to-top" role="button" aria-label="Back to Top">
        <i className="ion-ios-arrow-up"></i>
      </a>
    </>
  );
};

export default Footer;
