// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import Header from "./components/Header";

const App = () => {
  return (
    <Router>
      {/* <Header />  Optional header */}
      <Navbar />  {/* Navbar with links */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        {/* Uncomment additional routes as needed */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/service" element={<Service />} /> */}
      </Routes>
      <Footer />  {/* Footer */}
    </Router>
  );
};

export default App;
