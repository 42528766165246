import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div>
      <div className="menu-icon fas fa-bars"></div>
      <section className="home">
        <canvas
          className="background"
          width="1452"
          height="800"
          style={{ width: "100%", height: "70%" }}
        ></canvas>
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
      </section>
    </div>
  );
};

export default Header;
