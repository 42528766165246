import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [headerStyle, setHeaderStyle] = useState({
    background: "none",
    boxShadow: "none",
  });

  const [menuToggle, setMenuToggle] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 35) {
      setHeaderStyle({
        background: "#002e5f",
        boxShadow: "0 .2rem .5rem rgba(183, 49, 49, 0.4)",
      });
    } else {
      setHeaderStyle({
        background: "none",
        boxShadow: "none",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleBackToTopVisibility = () => {
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleBackToTopVisibility);
    return () => {
      window.removeEventListener("scroll", handleBackToTopVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    <>
      <header className="header" style={headerStyle}>
        <div className="logo-container">
          <div className="brand-name">
            <span>EssDee Green</span>
            <span>Technologies</span>
          </div>
        </div>

        <div
          className={`menu-icon fas fa-bars ${menuToggle ? "active" : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        ></div>

        <nav className={`navbar ${menuToggle ? "active" : ""}`}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><a href="/#about">About</a></li>
            <li><a href="/#service">Services</a></li>
            <li><a href="/#team">Team</a></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>

      {showBackToTop && (
        <div className="back-to-top" onClick={scrollToTop}>
          <i className="fa fa-arrow-up"></i>
        </div>
      )}
    </>
  );
};

export default Navbar;
