/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import "./Home.css";

const Home = () => {
  const [rightCount, setRightCount] = useState(0);
  const [centerCount, setCenterCount] = useState(0);
  const [leftCount, setLeftCount] = useState(0);

  useEffect(() => {
    // Setup counter animation
    const counters = document.querySelectorAll('.counter');
    const speed = 120;

    const updateCount = (counter, target, setter) => {
      let count = 0;
      const inc = target / speed;
      
      const interval = setInterval(() => {
        count += inc;
        if (count >= target) {
          clearInterval(interval);
          count = target;
        }
        setter(Math.ceil(count));
      }, 1);
    };

    counters.forEach(counter => {
      const target = +counter.getAttribute('data-target');
      const counterName = counter.classList[1];

      if (counterName === "Rights") {
        updateCount(counterName, target, setRightCount);
      } else if (counterName === "center") {
        updateCount(counterName, target, setCenterCount);
      } else if (counterName === "left") {
        updateCount(counterName, target, setLeftCount);
      }
    });

    return () => {
      setRightCount(0);
      setCenterCount(0);
      setLeftCount(0);
    };
  }, []);

  return (
    <>
     <div>
  <Header />
  </div>
      {/* About Us Section */}
      <section id="about" className="about">
      <div className="section-head-1 col-sm-12" style={{ textAlign: 'center' }}>
        <h4 className="heading">About Us</h4>
        <div className="row">
          <div className="content">
        </div>
            <h3>Empowering Industries with Skilled Talent</h3>
            <h3>Founded in 2022 in the scenic state of Uttarakhand, India, EssDee Green Technologies Pvt. Ltd. is a leading human resources and manpower consultancy. We specialize in sourcing skilled professionals for various industries, with a primary focus on renewable energy and solar power.</h3>
            <a href="#" aria-label="Read more about us"><button className="btn">Read More</button></a>
          </div>
        </div>
      </section>

      {/* Our Recruitment Process Section */}
      <section className="pt-5 pb-5" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <div className="row">
            <div className="section-head-1 col-sm-12" style={{ textAlign: 'center' }}>
              <h4 style={{ marginTop: '4rem' }} id="portfolio-1">Our Recruitment Process: Precision and Excellence</h4>
            </div>
        </div>
        </div>
        <div className="container">
          <div className="infographic">
            <div className="step">
              <div className="step-icon"><i className="fas fa-envelope"></i></div>
              <h2>Step 1: Understanding Client Needs</h2>
              <p>We carefully analyze each client's unique requirements and industry specifics.</p>
            </div>
            <div className="step">
              <div className="step-icon"></div>
              <h2>Step 2: Sourcing Talent</h2>
              <p>Our extensive network helps us identify the most suitable candidates.</p>
            </div>
            <div className="step">
              <div className="step-icon"></div>
              <h2>Step 3: Rigorous Screening</h2>
              <p>We conduct thorough assessments to ensure perfect candidate-job matches.</p>
            </div>
            <div className="step">
              <div className="step-icon"></div>
              <h2>Step 4: Placement and Follow-up</h2>
              <p>We facilitate smooth onboarding and provide ongoing support to clients.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Renewable Energy Focus Section */}
      <section className="pt-5 pb-5" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <div className="row">
            <div className="section-head-1 col-sm-12" style={{ textAlign: 'center' }}>
              <h4 style={{ marginTop: '4rem' }} id="portfolio-1">Renewable Energy Focus: Powering the Future</h4>
              <h5>We help build high-quality digital solutions and products, while delivering world-class services to clients.</h5>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_one"><i className="fas fa-solar-panel"></i></span>
                <h6>Solar Energy Specialists</h6>
                <p>Expert technicians for solar panel installation and maintenance.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_two"><i className="fas fa-globe"></i></span>
                <h6>Renewable Energy Consultants</h6>
                <p>Professionals guiding businesses in adopting green technologies.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_three"><i className="fas fa-hand-holding-heart"></i></span>
                <h6>Sustainability Managers</h6>
                <p>Leaders driving eco-friendly practices in organizations.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Counter Section */}
      <section className="counters">
        <div className="container">
          <div>
            <i className="fas fa-trophy fa-5x"></i>
            <div className="counter Rights" data-target="100">{rightCount}</div>
            <h3>Successful Placements</h3>
            <h3>We've helped numerous companies find their ideal candidates.</h3>
          </div>
          <div>
            <i className="fas fa-gift fa-5x"></i>
            <div className="counter center" data-target="25">{centerCount}</div>
            <h3>Industries Served</h3>
            <h3>Our expertise spans across various sectors, including renewable energy.</h3>
          </div>
          <div>
            <i className="fas fa-leaf fa-5x"></i>
            <div className="counter left" data-target="95">{leftCount}</div>
            <h3>Client Satisfaction</h3>
            <h3>We pride ourselves on maintaining high client satisfaction rates.</h3>
          </div>
        </div>
      </section>

      {/* Specialized Trades Section */}
      <section className="pt-5 pb-5" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <div className="row">
            <div className="section-head-1 col-sm-12" style={{ textAlign: 'center' }}>
              <h4 style={{ marginTop: '4rem' }} id="portfolio-1">Specialized Trades: Meeting Industry Demands</h4>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_one"><i className="fas fa-bolt"></i></span>
                <h6>Skilled Tradespeople</h6>
                <p>We source experienced fitters, plumbers, electricians, and AC technicians.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_one"><i className="fas fa-drafting-compass"></i></span>
                <h6>Construction Experts</h6>
                <p>Our network includes proficient carpenters and masons for building projects.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                <span className="icon feature_box_col_one"><i className="fas fa-handshake"></i></span>
                <h6>Customized Solutions</h6>
                <p>We tailor our recruitment services to match specific industry requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Team Section */}
      <section className="pt-5 pb-5" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <div className="row">
            <div className="section-head-1 col-sm-12" style={{ textAlign: 'center' }}>
           <h4 style={{ marginTop: '-4rem' }} id="portfolio-1">Our Team: Driven by Excellence</h4>
            </div>
            <div className="values-container">
              <div className="item value icon-excellence">
              <i className="fas fa-brain fa-5x"></i><h3>Expertise</h3>
                <p>Our team comprises seasoned HR professionals with deep industry knowledge.</p>
              </div>
              <div className="item value icon-dedication">
              <i className="fas fa-cogs fa-5x"></i> <h3>Commitment</h3>
                <p>We are passionate about ensuring the right fit for both clients and candidates.</p>
              </div>
              <div className="item value icon-collaboration">
              <i className="fas fa-users fa-5x"></i> <h3>Collaboration</h3>
                <p>We work closely with clients to understand their unique business needs.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
